//获取一个日期所在月的所有天
export function getMonthDays(dateStr) {
    const date = new Date(dateStr)
    let year = date.getFullYear()  // 获取年份
    let month = date.getMonth() + 1  // 获取月份（注意月份要加1）
    let daysInMonth = new Date(year, month, 0).getDate()  // 获取当月天数
    let days = []
    for (let i = 1; i <= daysInMonth; i++) {
        days.push(i)
    }
    return days
}

// 获取一个日期所在星期的所有天
export function getWeekDays(dateStr) {
    const date = new Date(dateStr)
    let days = [];
    let day = date.getDay(); // 获取当前星期的索引，0 表示星期天，1 表示星期一
    // 获取当前星期的第一天（星期一）
    let firstDay = new Date(date.getFullYear(), date.getMonth(), date.getDate() - day + 1);
    // 获取当前星期的所有天
    for (let i = 0; i < 7; i++) {
        let currentDate = new Date(firstDay.getFullYear(), firstDay.getMonth(), firstDay.getDate() + i);
        days.push(`${formatNumber(currentDate.getMonth()+1)}-${formatNumber(currentDate.getDate())}`);
    }
    return days;
}
// 补0函数
function formatNumber(n) {
    n = n.toString();
    return n[1] ? n : '0' + n;
}

// KB转GB
export function convertKBToGB(kilobytes) {
    var gigabytes = kilobytes / 1024 / 1024;
    return gigabytes.toFixed(2);
}

// 获取一个日期的天
export function getDayOfMonth(dateString) {
    const date = new Date(dateString);
    return date.getDate();
}

//将秒数转换为时分秒的格式
export function formatTime(seconds) {
    let hours = parseInt(Math.floor(seconds / 3600));
    let minutes = parseInt(Math.floor((seconds - (hours * 3600)) / 60));
    let remainingSeconds = parseInt(seconds - (hours * 3600) - (minutes * 60));
    // 使用三元表达式确保格式的正确性
    let hoursStr = (hours < 10) ? "0" + hours : hours;
    let minutesStr = (minutes < 10) ? "0" + minutes : minutes;
    let secondsStr = (remainingSeconds < 10) ? "0" + remainingSeconds : remainingSeconds;
    return `${hoursStr}:${minutesStr}:${secondsStr}`;
}

// 返回本地日期或格式化传入日期
export function getCurrentDate (time=null, validate = true, separator = ['-', ':']) {
    let currentTimeObj = time?new Date(time):new Date();
    let year = currentTimeObj.getFullYear();
    let month = (currentTimeObj.getMonth() + 1);
    month = month >= 10 ? month : '0' + month;
    let day = currentTimeObj.getDate();
    day = day >= 10 ? day : '0' + day;
    let hour = currentTimeObj.getHours();
    hour = hour >= 10 ? hour : '0' + hour;
    let minute = currentTimeObj.getMinutes();
    minute = minute >= 10 ? minute : '0' + minute;
    let second = currentTimeObj.getSeconds();
    second = second >= 10 ? second : '0' + second;
    if (validate) {
        let ymd = [year, month, day];
        let his = [hour, minute, second];
        return ymd.join(separator[0]) + ' ' + his.join(separator[1]);
    } else {
        let ymd = [year, month, day];
        return ymd.join(separator[0]);
    }
}


// 获取多少天前的日期
export const getDateNDaysAgo = (daysAgo, segmentation = '') => {
    const today = new Date();
    const before = new Date(today.getTime() - (daysAgo * 24 * 60 * 60 * 1000));
    const year = before.getFullYear();
    const month = (before.getMonth() + 1).toString().padStart(2, '0');
    const day = before.getDate().toString().padStart(2, '0');
    return `${year}${segmentation}${month}${segmentation}${day}`
}

// 返回两个日期中间的所有日期,包含传入的两个日期
export function getDatesBetween(startDate, endDate) {
    let dates = [];
    startDate = new Date(startDate);
    endDate = new Date(endDate);

    while (startDate <= endDate) {
        let year = startDate.getFullYear();
        let month = (startDate.getMonth() + 1).toString().padStart(2, '0');
        let day = startDate.getDate().toString().padStart(2, '0');

        let formattedDate = year + "-" + month + "-" + day;
        dates.push(formattedDate);

        startDate.setDate(startDate.getDate() + 1);
    }

    return dates;
}

// 根据日期获取月份
export function getMonth(dateString) {
    const currentDate = dateString ? new Date(dateString) : new Date();
    return currentDate.getMonth() + 1;
}