import service from "@/utils/ajax"

//订单数量
export const dp_orderNumCount = (params)=>{
    return service({
        url:"/v1/statisticsChart/dp_orderNumCount",
        method:"GET",
        params
    })
}

//搜索量
export const dp_searchNumCount = (params)=>{
    return service({
        url:"/v1/statisticsChart/dp_searchNumCount",
        method:"GET",
        params
    })
}

//交易总额
export const dp_orderAmount = (params)=>{
    return service({
        url:"/v1/statisticsChart/dp_orderAmount",
        method:"GET",
        params
    })
}

//数据回写
export const dp_dataWriyBackCount = (params)=>{
    return service({
        url:"/v1/statisticsChart/dp_dataWriyBackCount",
        method:"GET",
        params
    })
}

//大屏-总销售额-会员-流量包
export const dp_salesVolumeCount = (params)=>{
    return service({
        url:"/v1/statisticsChart/dp_salesVolumeCount",
        method:"GET",
        params
    })
}

//大屏-套餐销售排名
export const dp_packageSales = (params)=>{
    return service({
        url:"/v1/statisticsChart/dp_packageSales",
        method:"GET",
        params
    })
}

//大屏-访问情况
export const dp_visitsCount = (params)=>{
    return service({
        url:"/v1/statisticsChart/dp_visitsCount",
        method:"GET",
        params
    })
}
